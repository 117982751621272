<template>
  <div class="study-psc">
    <psc-box-1></psc-box-1>
    <psc-box-2></psc-box-2>
    <psc-box-3></psc-box-3>
  </div>
</template>
<script>
import PscBox1 from "./psc-box1.vue";
import PscBox2 from "./psc-box2.vue";
import PscBox3 from "./psc-box3.vue";
export default {
  components: { PscBox1, PscBox2, PscBox3 },
  data() {
    return {};
  },
};
</script>
