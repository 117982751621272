<template>
  <div class="dapp-box">
    <div class="m-title4">
      <!-- PSC特征 -->
      {{ $t("study.pscBox2T1") }}
    </div>
    <div class="m-p">
      <!-- 无论您是何背景，无论您 -->

      {{ $t("study.pscBox2T2") }}
    </div>
    <div class="box">
      <div
        class="m-item3"
        v-for="(item, index) in $t('study.pscBox2List')"
        :key="index"
      >
        <div class="img">
          <img
            :src="
              require(`../../assets/images/v4/study/tokenBox2${index + 1}.png`)
            "
            alt=""
          />
        </div>
        <div class="t">{{ item.title }}</div>
        <div class="link"></div>
        <div class="android">{{ item.subTitle }}</div>
        <div class="p">
          {{ item.text }}
        </div>
        <!-- <div class="more">
          <a href="javascript:;">
            {{ item.ft }}
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-arrowRight"></use>
            </svg>
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.dapp-box {
  padding-top: (37 / 3.75vw);
  // padding-bottom: 250px;
  background-image: url("../../assets/images/v4/home/box2Bg.png");
  background-repeat: no-repeat;
  background-position: 0 60%;
  text-align: center;
  .m-p {
    padding: 0 (14 / 3.75vw);
    margin-top: (15 / 3.75vw);
  }
  .box {
    margin-top: (37 / 3.75vw);
    // background-color: pink;
    width: 100%;
    // height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
