<template>
  <div class="dapp-box">
    <div class="m-title4">
      <!-- 探索PSC -->
      {{ $t("study.pscBox3T1") }}
    </div>

    <div class="box">
      <div class="img">
        <img src="../../assets/images/v4/use/m/pscBox31.png" alt="" />
      </div>
      <div class="item">
        <div class="t">
          <!-- 基于PSC创造一些玩意 -->
          {{ $t("study.pscBox3T2") }}
        </div>
        <div class="p">
          <!-- 如果您想尝试进行PSC相关 -->
          {{ $t("study.pscBox3P") }}
        </div>
        <div class="m-but2" @click="uti.goPath(store.link.study.pscBut2)">
          <!-- 开始构建 -->
          {{ $t("study.pscBox3But") }}
        </div>
      </div>
    </div>
    <div class="box">
      <div class="img">
        <img src="../../assets/images/v4/use/m/pscBox32.png" alt="" />
      </div>
      <div class="item">
        <div class="t">
          <!-- PSC社区 -->
          {{ $t("study.pscBox3BoxT") }}
        </div>
        <div class="p">
          <!-- 我们的社区成员来自 -->
          {{ $t("study.pscBox3BoxP") }}
        </div>
        <div class="m-but2" @click="uti.goPath(store.link.study.pscBut2)">
          <!-- 我们的社区 -->
          {{ $t("study.pscBox3BoxB") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.dapp-box {
  padding-top: (30 / 3.75vw);
  // padding-bottom: 250px;
  background-image: url("../../assets/images/v4/home/box2Bg.png");
  background-repeat: no-repeat;
  background-position: 0 60%;
  text-align: center;
  .box {
    // height: 529px;
    margin-bottom: 42px;
    // background-color: pink;
    // display: flex;
    .img {
      // padding: 0 70px;
      padding-top: (47 / 3.75vw);
      img {
        width: (179 / 3.75vw);
      }
    }
    .item {
      flex: 1;
      .t {
        font-size: (18 / 3.75vw);
        margin-top: (28 / 3.75vw);
        font-weight: bold;
        color: #333333;
      }
      .p {
        margin-top: (15 / 3.75vw);
        font-size: (13.33 / 3.75vw);
        line-height: (25 / 3.75vw);
        padding: 0 (14 / 3.75vw);
        font-weight: 500;
        color: #999999;
      }
      .m-but2 {
        margin-top: (47 / 3.75vw);
      }
    }
  }
}
</style>
