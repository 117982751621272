<template>
  <div class="com-item">
    <div class="m-title">
      <!-- PSC向所有人开放 -->
      {{ $t("study.pscBox1T2") }}
    </div>
    <div class="m-p">
      <!-- PolySmartChain是一个快速、高吞吐量的开源智能合约平台，它结合了突破性的技术，为去中心化的应用程序、系统和社会提供无与伦比的安全性和可持续性。您只需要一个钱包就可以一起参与。 -->

      {{ $t("study.pscBox1P") }}
    </div>
    <div class="m-but2" @click="uti.goPath(store.link.study.pscBut1)">
      <!-- 探索PSC -->
      {{ $t("study.pscBox1But") }}
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  // height: (357.33 / 3.75vw);
  // background: linear-gradient(0deg, #dae6f2, #edf4fc);
  background-color: #e8f3ff;
  background-image: url("../../assets/images/v4/use/m/pscBox1Bg.png");
  background-size: (184.67 / 3.75vw) (145.33 / 3.75vw);
  background-position: right bottom;
  background-repeat: no-repeat;
  padding-top: (115 / 3.75vw);
  padding-bottom: (52 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .m-p {
    margin-top: (22 / 3.75vw);
    padding: 0 (14 / 3.75vw);
  }
  .m-but2 {
    margin-top: (49 / 3.75vw);
  }
}
</style>
